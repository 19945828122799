import React from "react";

export default class Discord extends React.Component {
  componentDidMount() {
    window.location.replace("https://discord.gg/agWa9QtWRd");
  }

  render() {
    return <div />;
  }
}
